import React from 'react';

const Forhipo = () => {
  return (
    <div>
      <nav className="bg-gray-900 sticky top-0 z-10">
        <div className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <img className="h-8 w-auto" src="/img/whitefavicon.svg" alt="Elmali Tech" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400">
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a href="/#services" className="text-sm font-semibold leading-6 text-white">Services</a>
            <a href="/#cases" className="text-sm font-semibold leading-6 text-white">Latest Works</a>
            <a href="/contact-us" className="text-sm font-semibold leading-6 text-white">Contact Us</a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="https://crm.elmaligroup.com" className="text-sm font-semibold leading-6 text-white">Log in to Customer Panel <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </nav>

      <div className="bg-white px-6 py-20 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">New Platform For TikTok Influencers</h1>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Forhipo, A Social Media Marketing Tool Of BlueMind Company</h2>
          <p className="mt-6 text-xl leading-8">The platform allows users to purchase social media marketing services through an API connection with service providers. Now, It is a simple to engage with TikTok influencers and help consumers to increase real TikTok influencers. Select target criteria, find a right TikTok influencer and choose popular package to get action.</p>
          <p className="mt-6 text-xl leading-8">Forhipo team had a plan to expand the platform to other social media platforms while ensuring that the current services provided for TikTok is efficient and effective. This was achieved by conducting market research, analyzing user feedback, and collaborating with service providers to develop new services.</p>
          <figure className="mt-16">
            <img className="aspect-video rounded-xl bg-gray-50 object-cover" src="/img/forhipo.png" alt="" />
          </figure>
          <p className="mt-6 text-xl leading-8">As a team, we’d started Forhipo project to acquire the company a significant number of users within a short period. It is designed end to end architecture with front-end, back-end, API connections. Thus, while the platform is fast, user-friendly, responsive on UX side, also scalable and secure on back-end with data encryption. The platform is connected with payment services easily. In terms of consumers, critical point making it responsive screen sizes and accessible to users on different devices</p>
          <p className="mt-6 text-xl leading-8">The successful implementation of the platform enables Forhipo to acquire new users, provide seamless payment service, and expand its services on Worldwide</p>
          <p className="mt-6 text-xl leading-8">The beta version of Forhipo currently provides services for only TikTok. But number of social media connections will be added soon</p>
        </div>
      </div>

      <footer className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-8 mx-auto">
          <div className="flex flex-col items-center text-center">
            <a href="#">
              <img className="w-auto h-7" src="/img/elmalitechcolored.svg" alt="" />
            </a>
            <div className="container flex flex-col items-center px-4 py-12 mx-auto text-center">
              <h2 className="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
                Bring your Business to the <span className="text-blue-500">next level.</span>
              </h2>
              <div className="flex flex-col mt-4 sm:flex-row sm:items-center sm:justify-center">
                <a href="https://crm.elmaligroup.com" className="flex items-center justify-center order-1 w-full px-2 py-2 mt-3 text-sm tracking-wide text-gray-600 capitalize transition-colors duration-300 transform border rounded-md sm:mx-2 dark:border-gray-400 dark:text-gray-300 sm:mt-0 sm:w-auto hover:bg-gray-50 focus:outline-none focus:ring dark:hover:bg-gray-800 focus:ring-gray-300 focus:ring-opacity-40">
                  <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/></svg>
                  <span className="mx-1">Get Meeting</span>
                </a>
                <a href="/contact-us" className="w-full px-5 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center sm:flex-row sm:justify-between">
              <p className="text-sm text-gray-500 px-6">© Copyright 2023. All Rights Reserved.</p>
              <div className="flex mt-3 -mx-2 sm:mt-0">
                <a href="#" className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit">
                  Privacy
                </a>
                <a href="#" className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300" aria-label="Reddit">
                  Cookies
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Forhipo;
