import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LoadingScreen from "../components/LoadingScreen"; 

const MySwal = withReactContent(Swal);

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, "contacts"), formData);
      MySwal.fire({
        title: 'Thank You!',
        text: 'Your message has been successfully sent.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      MySwal.fire({
        title: 'Oops...',
        text: 'Something went wrong. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-6 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-12">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Contact Elmali Tech</h1>
          <p className="mt-4 text-lg text-gray-600">We'd love to hear from you! Fill out the form below or reach us directly at our offices.</p>
        </div>

        <div className="flex flex-col lg:flex-row gap-12">
          <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2 space-y-6">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  placeholder="John Doe"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  placeholder="you@example.com"
                  required
                />
              </div>
              <div className='col-span-2'>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  placeholder="+1 (555) 123-4567"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  rows="4"
                  placeholder="Your message"
                  required
                />
              </div>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="w-full py-3 px-4 border border-transparent text-lg font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-md transition duration-150 ease-in-out"
              >
                Send Message
              </button>
            </div>
          </form>

          <div className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2 space-y-8">
            <div>
              <h3 className="text-2xl font-semibold text-blue-600">Elmali Tech HQ</h3>
              <p className="text-gray-700 mt-2">
                Selçuk Yaşar Kampüsü, Üniversite Caddesi, Ağaçlı Yol No:37/39, 35000 Bornova/İzmir
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-blue-600">Istanbul Office</h3>
              <p className="text-gray-700 mt-2">
                Fulya, Yeşilçimen Sokağı Polat Tower Residence Bağımsız Bölüm 12/430, 34394 Şişli/İstanbul
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12 rounded-lg overflow-hidden shadow-lg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.564616407978!2d28.99889000000001!3d41.0566493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab762ac0fbd99%3A0xbcd93d85543b5507!2sElmali%20Tech!5e0!3m2!1str!2str!4v1724781456987!5m2!1str!2str"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
