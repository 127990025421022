import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';
import DOMPurify from "dompurify";
import FeaturedCaseStudies from "./FeaturedCaseStudies";
import LoadingScreen from "../components/LoadingScreen"; // LoadingScreen bileşenini ekleyin

const Home = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(true); // Loading durumunu yönetecek state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const caseStudyCollection = collection(db, "caseStudies");
        const caseStudySnapshot = await getDocs(caseStudyCollection);
        const caseStudyList = caseStudySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCaseStudies(caseStudyList);
      } catch (error) {
        console.error("Error fetching case studies:", error);
      } finally {
        setLoading(false); // Veri çekme işlemi tamamlandığında loading durumunu kapat
      }
    };

    fetchCaseStudies();
  }, []);

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + "...";
  };

  if (loading) {
    return <LoadingScreen />; // Loading durumunda loading ekranını göster
  }

  return (
    <div>
      <header className="bg-gray-900 pattern">
        <div className="container px-6 mx-auto">
          <div className="flex flex-col items-center py-6 lg:h-[36rem] lg:flex-row">
            <div className="lg:w-1/2">
              <h2 className="text-3xl font-semibold text-gray-100 lg:text-4xl">
                Unlock Your Business Potential with Custom Software Solutions
              </h2>
              <p className="mt-4 text-gray-100">
                Elmali Tech Software House empowers businesses with innovative software solutions. We specialize in providing cutting-edge, reliable, and scalable tech solutions to tech. companies, startups and ISVs worldwide.
              </p>
              <div className="mr-auto place-self-center lg:col-span-7 pt-10">
                <p className="max-w-2xl mb-4 text-2xl font-medium tracking-tight leading-none xl:text-sm text-white">
                  Come be a part of our growing family of <strong className="text-lg"> +100 </strong> satisfied customers!
                </p>
                <div className="flex flex-wrap md:flex-nowrap gap-10 mt-3">
                  <a href="https://www.segmenthub.com/en/">
                    <img src="/img/segmenthubgray.svg" className="w-28" alt="Segmenthub Logo" />
                  </a>
                  <a href="https://biletino.com/tr/">
                    <img src="/img/Biletino.png" className="w-28" alt="Biletino Logo" />
                  </a>
                  <a href="https://pitgrowth.com">
                    <img src="/img/pitgrowth.svg" className="w-28" alt="Pitgrowth Logo" />
                  </a>
                  <a href="https://tpayout.co">
                    <img src="/img/Tpayout.png" className="w-28" alt="Tpayout Logo" />
                  </a>
                  <a href="https://www.prolinepvc.com/tr/anasayfa/">
                    <img src="/img/prolinesvggray.svg" className="w-28" alt="Proline Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex mt-8 lg:w-1/2 lg:justify-end lg:mt-0">
              <img src="/img/text-editor-dark.svg" alt="" />
            </div>
          </div>
        </div>
      </header>

      <section className="bg-white dark:bg-gray-900 py-10" id="services">
        <div className="container mx-auto px-6">
          <h1 className="text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white">
            Explore our awesome <span className="underline decoration-blue-500">Services</span>
          </h1>
          <div className="swiper mySwiper mt-8">
            <Swiper loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} slidesPerView={3} spaceBetween={10} modules={[Autoplay]} breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 10 },
              640: { slidesPerView: 2, spaceBetween: 20 },
              768: { slidesPerView: 3, spaceBetween: 30 }
            }}>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Web Development</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    A well-designed and developed website is essential for any business that wants to be successful online. It's your first point of contact with potential customers, and it can make or break their impression of your company. Our web development services can help you create a website that is both visually appealing and functionally effective.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Web App Development</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Web applications are becoming increasingly important for businesses of all sizes. They can automate tasks, improve efficiency, and provide a better customer experience. Our web app development services can help you create a custom web app that meets your specific needs.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Mobile Development</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Mobile apps are now essential for businesses that want to reach a wider audience. They allow you to connect with customers on their own terms, and they can be a powerful tool for marketing and sales. Our mobile development services can help you create a custom mobile app that will help you grow your business.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 6.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">CRM Development</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Customer relationship management (CRM) software is essential for businesses that want to keep track of their customers and interactions. It can help you improve customer service, increase sales, and boost customer loyalty. Our CRM development services can help you choose the right CRM software for your business and implement it effectively.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">CMS Development</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Content management systems (CMS) make it easy to create and manage website content. They can save you time and money, and they give you more control over your website. Our CMS development services can help you choose the right CMS for your needs and implement it effectively.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">CTO as a Service</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    A CTO (Chief Technology Officer) is responsible for the overall technical strategy of a business. They help to ensure that the business's technology is aligned with its goals, and they provide guidance on technical matters. Our CTO as a Service offering can help you get the most out of your technology and achieve your business goals.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Outsource Technical Team</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Outsourcing your technical team can help you save money and get access to specialized skills. Our outsource technical team service can provide you with skilled IT professionals who can help you with a variety of tasks, from web development to cloud migration.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Cloud Optimization</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Cloud computing can be a cost-effective and scalable way to host your business's applications and data. However, it's important to make sure that you're getting the most out of your cloud infrastructure. Our cloud optimization services can help you optimize your cloud usage and save money.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="space-y-3">
                  <span className="inline-block p-3 bg-blue-100 rounded-full text-blue-500 dark:bg-blue-500 dark:text-gray-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                    </svg>
                  </span>
                  <h1 className="text-xl font-semibold text-gray-700 capitalize">Cloud Migration</h1>
                  <p className="text-gray-500 dark:text-gray-600 text-sm">
                    Migrating your business's data, applications, and services to the cloud can be a complex task. However, it can also be a great way to improve your IT infrastructure and reduce costs. Our cloud migration services can help you migrate your business to the cloud smoothly and efficiently.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <FeaturedCaseStudies />

    </div>
  );
}

export default Home;
