// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBQH9WgcX7BQaBhvGdt6knB-KhK2yXZfKg",
    authDomain: "elmali-tech-cfccb.firebaseapp.com",
    projectId: "elmali-tech-cfccb",
    storageBucket: "elmali-tech-cfccb.appspot.com",
    messagingSenderId: "702013008995",
    appId: "1:702013008995:web:1aea0f45759c53f94b5c56",
    measurementId: "G-02YCGFJFM6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { auth, storage, db };
