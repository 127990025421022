import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const FeaturedCaseStudies = () => {
  const [featuredCaseStudies, setFeaturedCaseStudies] = useState([]);

  useEffect(() => {
    const fetchFeaturedCaseStudies = async () => {
      const caseStudyCollection = collection(db, "caseStudies");
      const q = query(caseStudyCollection, where("isFeatured", "==", true));
      const querySnapshot = await getDocs(q);
      const caseStudyList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeaturedCaseStudies(caseStudyList);
    };

    fetchFeaturedCaseStudies();
  }, []);

  const getTextContent = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <section className="bg-white dark:bg-white" id="cases">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-gray-900 capitalize lg:text-3xl dark:text-gray-900">
          Latest Works
        </h1>
        <p className="text-gray-600">
          You can find some case studies completed from us
        </p>
        <div className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-5 sm:pt-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {featuredCaseStudies.map((caseStudy) => (
            <article
              key={caseStudy.id}
              className="flex max-w-xl flex-col items-start justify-between"
            >
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <Link to={`/case-study/${caseStudy.id}`}>
                    <span className="absolute inset-0"></span>
                    {caseStudy.title}
                  </Link>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                  {getTextContent(DOMPurify.sanitize(caseStudy.content))}
                </p>
              </div>
              <div className="flex justify-between items-center mt-4">
                <div className="flex items-center space-x-4">
                  <p className="line-clamp-3 text-sm leading-6 text-gray-600">
                    Customer:
                  </p>
                  {caseStudy.customerLogo && (
                    <img
                      className="w-7 h-7 rounded-full"
                      src={caseStudy.customerLogo}
                      alt={caseStudy.customerName}
                    />
                  )}
                </div>
              </div>
            </article>
          ))}
        </div>
        <div className="mt-8 text-center">
          <Link
            to="/cases"
            className="inline-block px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
          >
            See All
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedCaseStudies;
