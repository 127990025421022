import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import DOMPurify from "dompurify";

const CaseStudyDetail = ({ setIsLoading }) => {
  const { id } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      setIsLoading(true); // Loading başlatılır
      try {
        const docRef = doc(db, "caseStudies", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Case Study Data:", data); // Veri içeriğini kontrol etmek için
          setCaseStudy(data);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching case study:", error);
      } finally {
        setIsLoading(false); // Loading durdurulur
      }
    };

    fetchCaseStudy();
  }, [id, setIsLoading]);

  if (!caseStudy) {
    return null; // Loading tamamlanana kadar bu alan boş bırakılabilir
  }

  return (
    <div>
      <div className="bg-white px-6 py-20 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {caseStudy.title}
          </h1>
          <div
            className="mt-6 text-xl leading-8"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(caseStudy.content),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetail;
