import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DOMPurify from "dompurify";
import "../index.css";

const MySwal = withReactContent(Swal);

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <aside className="w-64 bg-blue-600 text-white p-4">
      <h2 className="text-xl font-bold mb-4">Dashboard Menu</h2>
      <ul>
        <li>
          <button
            className="w-full text-left py-2 px-4 rounded hover:bg-blue-700"
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </button>
        </li>
        <li>
          <button
            className="w-full text-left py-2 px-4 rounded hover:bg-blue-700"
            onClick={() => navigate("/case-study-form")}
          >
            Case Study Ekle
          </button>
        </li>
      </ul>
    </aside>
  );
};

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [caseStudies, setCaseStudies] = useState([]);
  const [showFeaturedOnly, setShowFeaturedOnly] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      const caseStudyCollection = collection(db, "caseStudies");
      const caseStudySnapshot = await getDocs(caseStudyCollection);
      const caseStudyList = caseStudySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCaseStudies(caseStudyList);
    };

    fetchCaseStudies();
  }, []);

  const toggleFeatured = async (id, isFeatured) => {
    try {
      const docRef = doc(db, "caseStudies", id);
      await updateDoc(docRef, { isFeatured: !isFeatured });
      setCaseStudies(caseStudies.map(cs => cs.id === id ? { ...cs, isFeatured: !isFeatured } : cs));
      MySwal.fire({
        title: "Başarılı!",
        text: `Case Study başarıyla ${!isFeatured ? "öne çıkarıldı" : "normal hale getirildi"}.`,
        icon: "success",
        confirmButtonText: "Tamam",
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      MySwal.fire({
        title: "Hata!",
        text: "Case Study durumu değiştirilirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  };

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const filteredCaseStudies = showFeaturedOnly
    ? caseStudies.filter((caseStudy) => caseStudy.isFeatured)
    : caseStudies;

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 p-8">
        <header className="bg-white shadow p-4 mb-8 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-blue-600">
            Hoşgeldin {user && user.email}
          </h1>
        </header>
        <section className="mb-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-blue-600">Case Study'ler</h2>
            <div className="flex items-center">
              <label className="mr-2 text-gray-700">Yalnızca Öne Çıkanlar</label>
              <input
                type="checkbox"
                checked={showFeaturedOnly}
                onChange={() => setShowFeaturedOnly(!showFeaturedOnly)}
                className="mr-4 leading-tight"
              />
              <button
                onClick={() => navigate("/case-study-form")}
                className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
              >
                Case Study Ekle
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredCaseStudies.map((caseStudy, index) => (
              <div
                key={index}
                className={`bg-white shadow-md rounded p-4 cursor-pointer flex flex-col ${caseStudy.isFeatured ? "border-2 border-yellow-500" : ""}`}
              >
                <div>
                  <span className="inline-block bg-blue-100 text-blue-800 rounded-full px-3 py-0.5 text-sm font-medium">
                    Case Study
                  </span>
                  {caseStudy.isFeatured && (
                    <span className="inline-block ml-2 bg-yellow-100 text-yellow-800 rounded-full px-3 py-0.5 text-sm font-medium">
                      Öne Çıkan
                    </span>
                  )}
                </div>
                <p className="mt-4 text-xl font-semibold text-gray-900">
                  {truncateText(caseStudy.title, 50)}
                </p>
                <div className="mt-3 text-base text-gray-500 flex-grow" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateText(caseStudy.content, 100)) }}></div>
                {caseStudy.coverImage && (
                  <img src={caseStudy.coverImage} alt="Cover" className="mt-4 w-full h-40 object-cover rounded" />
                )}
                <div className="mt-6 flex items-center">
                  {caseStudy.customerLogo && (
                    <img src={caseStudy.customerLogo} alt="Customer Logo" className="h-10 w-10 rounded-full" />
                  )}
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{caseStudy.customerName}</p>
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={() => navigate(`/case-study/${caseStudy.id}`)}
                    className="bg-blue-500 text-white font-bold py-1 px-2 rounded hover:bg-blue-700"
                  >
                    Detaylar
                  </button>
                  <button
                    onClick={() => navigate(`/edit-case-study/${caseStudy.id}`)}
                    className="bg-yellow-500 text-white font-bold py-1 px-2 rounded hover:bg-yellow-700"
                  >
                    Düzenle
                  </button>
                  <button
                    onClick={() => toggleFeatured(caseStudy.id, caseStudy.isFeatured)}
                    className={`text-white font-bold py-1 px-2 rounded ${caseStudy.isFeatured ? "bg-gray-600 hover:bg-gray-800" : "bg-green-500 hover:bg-green-700"}`}
                  >
                    {caseStudy.isFeatured ? "Öne Çıkan Kaldır" : "Öne Çıkar"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
