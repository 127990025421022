// src/pages/EditCaseStudyForm.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { storage, db } from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Modal from "react-modal";

const MySwal = withReactContent(Swal);

Modal.setAppElement("#root");

const EditCaseStudyForm = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [content, setContent] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerModalIsOpen, setCustomerModalIsOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerLogo, setCustomerLogo] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      const imagesRef = ref(storage, "images/");
      const result = await listAll(imagesRef);
      const urls = await Promise.all(result.items.map((item) => getDownloadURL(item)));
      setExistingImages(urls);
    };
    fetchImages();
  }, []);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      const docRef = doc(db, "caseStudies", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setTitle(data.title);
        setCoverImage(data.coverImage);
        setContent(data.content);
        setImageUrls(data.imageUrls || []);
        setCustomerName(data.customerName);
        setCustomerLogo(data.customerLogo);
        setIsFeatured(data.isFeatured || false);
      } else {
        console.log("No such document!");
      }
    };
    fetchCaseStudy();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Firestore'da belgeyi güncelle
    try {
      const docRef = doc(db, "caseStudies", id);
      await updateDoc(docRef, {
        title,
        coverImage,
        content,
        imageUrls,
        customerName,
        customerLogo,
        isFeatured,
      });
      MySwal.fire({
        title: "Başarılı!",
        text: "Case study başarıyla güncellendi.",
        icon: "success",
        confirmButtonText: "Tamam",
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating document: ", error);
      MySwal.fire({
        title: "Hata!",
        text: "Case study güncellenirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // İlerleme göstergesi burada eklenebilir
      },
      (error) => {
        console.error("Upload failed", error);
        MySwal.fire({
          title: "Hata!",
          text: "Resim yüklenirken bir hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageUrls([...imageUrls, downloadURL]);
          setExistingImages([...existingImages, downloadURL]); // Yeni yüklenen resmi mevcut resimlere ekliyoruz
          MySwal.fire({
            title: "Başarılı!",
            text: "Resim başarıyla yüklendi.",
            icon: "success",
            confirmButtonText: "Tamam",
          });
        });
      }
    );
  };

  const handleRemoveImage = async (url) => {
    try {
      const imageRef = ref(storage, url);
      await deleteObject(imageRef);
      setImageUrls(imageUrls.filter((imageUrl) => imageUrl !== url));
      setExistingImages(existingImages.filter((image) => image !== url));
    } catch (error) {
      console.error("Error removing image: ", error);
    }
  };

  const handleSelectExistingImage = (url) => {
    setCoverImage(url);
    setModalIsOpen(false); // Seçim sonrası modali kapat
  };

  const handleSelectCustomerLogo = (url) => {
    setCustomerLogo(url);
    setCustomerModalIsOpen(false); // Seçim sonrası modali kapat
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openCustomerModal = () => {
    setCustomerModalIsOpen(true);
  };

  const closeCustomerModal = () => {
    setCustomerModalIsOpen(false);
  };

  const handleDelete = async () => {
    try {
      const docRef = doc(db, "caseStudies", id);
      await deleteDoc(docRef);
      MySwal.fire({
        title: "Başarılı!",
        text: "Case study başarıyla silindi.",
        icon: "success",
        confirmButtonText: "Tamam",
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error deleting document: ", error);
      MySwal.fire({
        title: "Hata!",
        text: "Case study silinirken bir hata oluştu.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white shadow-md rounded p-8 max-w-full mx-auto">
        <h2 className="text-2xl font-bold text-blue-600 mb-6">Case Study Düzenle</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Başlık
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerName">
              Müşteri Adı
            </label>
            <input
              type="text"
              id="customerName"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
              Kapak Fotoğrafı
            </label>
            <button
              type="button"
              onClick={openModal}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Kapak Fotoğrafı Seç
            </button>
            {coverImage && (
              <div className="mt-4">
                <img src={coverImage} alt="Cover" className="w-full h-40 object-cover rounded" />
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerLogo">
              Müşteri Logosu
            </label>
            <button
              type="button"
              onClick={openCustomerModal}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Müşteri Logosu Seç
            </button>
            {customerLogo && (
              <div className="mt-4">
                <img src={customerLogo} alt="Customer Logo" className="w-full h-40 object-cover rounded" />
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              İçerik
            </label>
            <ReactQuill
              value={content}
              onChange={setContent}
              className="mb-4 h-96"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Resimler
            </label>
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {imageUrls.map((url, index) => (
                <div key={index} className="relative">
                  <img src={url} alt={`upload-${index}`} className="w-full h-40 object-cover rounded" />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(url)}
                    className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1"
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="isFeatured">
              Öne Çıkar
            </label>
            <input
              type="checkbox"
              id="isFeatured"
              checked={isFeatured}
              onChange={(e) => setIsFeatured(e.target.checked)}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">Bu case study'i öne çıkar</span>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Güncelle
            </button>
            <button
              type="button"
              onClick={() => navigate("/dashboard")}
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              İptal
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Sil
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Kapak Fotoğrafı Seç"
        className="modal bg-white p-4 rounded shadow-md max-w-lg mx-auto"
        overlayClassName="modal-overlay fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Kapak Fotoğrafı Seç</h2>
        <input
          type="file"
          onChange={handleImageUpload}
          className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {existingImages.map((url, index) => (
            <div key={index} className="relative">
              <img src={url} alt={`existing-${index}`} className="w-full h-40 object-cover rounded" />
              <button
                type="button"
                onClick={() => handleSelectExistingImage(url)}
                className="absolute top-2 right-2 bg-blue-600 text-white rounded-full p-1"
              >
                Seç
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={closeModal}
          className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Kapat
        </button>
      </Modal>
      <Modal
        isOpen={customerModalIsOpen}
        onRequestClose={closeCustomerModal}
        contentLabel="Müşteri Logosu Seç"
        className="modal bg-white p-4 rounded shadow-md max-w-lg mx-auto"
        overlayClassName="modal-overlay fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold text-blue-600 mb-4">Müşteri Logosu Seç</h2>
        <input
          type="file"
          onChange={handleImageUpload}
          className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {existingImages.map((url, index) => (
            <div key={index} className="relative">
              <img src={url} alt={`existing-${index}`} className="w-full h-40 object-cover rounded" />
              <button
                type="button"
                onClick={() => handleSelectCustomerLogo(url)}
                className="absolute top-2 right-2 bg-blue-600 text-white rounded-full p-1"
              >
                Seç
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={closeCustomerModal}
          className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Kapat
        </button>
      </Modal>
    </div>
  );
};

export default EditCaseStudyForm;
