import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Forhipo from './pages/Forhipo';
import Pitgrowth from './pages/Pitgrowth';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import CaseStudyForm from './pages/CaseStudyForm';
import CaseStudyDetail from './pages/CaseStudyDetail';
import EditCaseStudyForm from "./pages/EditCaseStudyForm";
import Layout from './Layout';
import Cases from './pages/Cases';
import LoadingScreen from './components/LoadingScreen';

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Router>
      {isLoading && <LoadingScreen />}
      <Routes>
        <Route path="/" element={<Layout><Home setIsLoading={setIsLoading} /></Layout>} />
        <Route path="/contact-us" element={<Layout><ContactUs setIsLoading={setIsLoading} /></Layout>} />
        <Route path="/forhipo" element={<Layout><Forhipo setIsLoading={setIsLoading} /></Layout>} />
        <Route path="/login" element={<Layout><Login setIsLoading={setIsLoading} /></Layout>} />
        <Route path="/dashboard" element={<Dashboard setIsLoading={setIsLoading} />} />
        <Route path="/case-study-form" element={<CaseStudyForm setIsLoading={setIsLoading} />} />
        <Route path="/case-study/:id" element={<Layout><CaseStudyDetail setIsLoading={setIsLoading} /></Layout>} />
        <Route path="/edit-case-study/:id" element={<EditCaseStudyForm setIsLoading={setIsLoading} />} />
        <Route path="/cases" element={<Layout><Cases setIsLoading={setIsLoading} /></Layout>} />
      </Routes>
    </Router>
  );
};

export default App;
