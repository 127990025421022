import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
      <img
        className="h-16 w-auto mb-6 animate-bounce"
        src="/img/whitefavicon.svg"
        alt="Elmali Tech"
      />
      <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-500 mb-4"></div>
      <p className="text-white text-xl">Loading...</p>
    </div>
  );
};

export default LoadingScreen;
