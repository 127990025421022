import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const Cases = () => {
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      const caseStudyCollection = collection(db, "caseStudies");
      const caseStudySnapshot = await getDocs(caseStudyCollection);
      const caseStudyList = caseStudySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCaseStudies(caseStudyList);
    };

    fetchCaseStudies();
  }, []);

  const getTextContent = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <div className="container px-6 py-10 mx-auto">
      <h1 className="text-2xl font-semibold text-gray-900 capitalize lg:text-3xl dark:text-gray-900">
        All Case Studies
      </h1>
      <div className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-5 sm:pt-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {caseStudies.map((caseStudy) => (
          <article
            key={caseStudy.id}
            className="flex max-w-xl flex-col items-start justify-between"
          >
            <div className="group relative">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <Link to={`/case-study/${caseStudy.id}`}>
                  <span className="absolute inset-0"></span>
                  {caseStudy.title}
                </Link>
              </h3>
              <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                {getTextContent(DOMPurify.sanitize(caseStudy.content))}
              </p>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="flex items-center space-x-4">
                <p className="line-clamp-3 text-sm leading-6 text-gray-600">
                  Customer:
                </p>
                {caseStudy.customerLogo && (
                  <img
                    className="w-7 h-7 rounded-full"
                    src={caseStudy.customerLogo}
                    alt={caseStudy.customerName}
                  />
                )}
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Cases;
